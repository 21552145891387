/* You can add global styles to this file, and also import other style files */
@import "~bootstrap";
html, body { height: 100%;}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;   overflow-x: hidden!important;}
.customizedButton {
  color: white;
  background: #3F81FF;
}
*:focus {
  outline: none!important;
}
input {
  border-width: 0!important;
}

